import React from 'react'
import { Link } from 'gatsby'

//Components
import Menu from "components/Menu"
import MobilMenu from "components/MobilMenu"

// Hooks
import { useSiteConfigQuery } from 'Hooks/useSiteConfigQuery'

//Styles
import { Wrapper } from './Header.styles'

class Header extends React.Component{

    componentDidMount() {
        var scrollpos = window.scrollY;
        var header = document.querySelector(".head");
        var homelink = document.querySelector(".homelink");
        var homelinkspan = document.querySelectorAll(".homelink_span");
        
        

        function add_class_on_scroll() {
            header.classList.add("header-bg");
            homelink.classList.add("homelinkscroll");
            let i;

            for (i = 0; i < homelinkspan.length; i++) {
                homelinkspan[i].classList.add("homelinkspan");
              }


        }

        function remove_class_on_scroll() {
            header.classList.remove("header-bg");
            homelink.classList.remove("homelinkscroll");
            let i;

            for (i = 0; i < homelinkspan.length; i++) {
                homelinkspan[i].classList.remove("homelinkspan");
              }

        }

        window.addEventListener('scroll', function(){ 
            //Here you forgot to update the value
            scrollpos = window.scrollY;

            if(scrollpos > 150){
                add_class_on_scroll();
            }
            else {
                remove_class_on_scroll();
            }
        });
    }

    render(){
        const siteConfig = useSiteConfigQuery()

        return(
            <>
            <Wrapper className="head">          
                <Link className="homelink" to="/"><span className="homelink_span">R</span>estauranger <span className="homelink_span">B</span>orås
                </Link>
                    <Menu items={siteConfig.menu}  />
                    <MobilMenu items={siteConfig.menu}  />

            </Wrapper>
            </>
        )
    }   
}

export default Header