import styled from "styled-components"

export const Wrapper = styled.header`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  max-width: 1600px;
  width:100%;
  height: auto;
  margin: 0px auto;
  overflow: visible;
  z-index: 82;
  //position: absolute;
  top:0px;
  max-width:1600px;
  margin:0 auto;
  background: ${props => props.theme.colors.darkgray};
  padding-top: 15px;
  transition:.3s;
  position: relative;

  &.header-bg {
    background: ${props => props.theme.colors.darkgray};
    transition:.3s;
  }
  

  @media screen and (min-width: 1040px) {
    background: transparent;
    position: fixed;
    &.header-bg {
      background: rgb(20, 20, 20);
      transition:.3s;
      flex-direction: column;
      padding:0;
    }
  }

  a {
    color:white;
    font-weight: 100;
    font-size: 1.1rem;
    letter-spacing: 2px; 

    @media screen and (min-width: 768px) {
      font-size: 2rem;
      padding-top: 30px;
    }

    @media screen and (min-width: 1040px) {
      padding-top: 15px;

      .homelinkspan {
        font-size: 1.3rem !important;
      }
    }

    span {
      font-size: 1.3rem;
      color: ${props => props.theme.colors.prime};

      @media screen and (min-width: 768px) {
        font-size: 2rem;
        }
    }
  }

`

export const Logo = styled.img`
  max-width: 100px;
  max-height: 29px;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 768px) {
    
  }
`
