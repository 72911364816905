import styled from "styled-components"

export const CardHolder = styled.div`
.shadow-top {
    position:fixed;
    top:55px;
    z-index:2;
    width: 100%;
    height: 15vh;
    transitin: .3s;
    max-width: 1600px;
    display: none;
}
.shadow {
    background:
    linear-gradient(
        to bottom, 
        rgba(34, 37, 38,1),
        rgba(34, 37, 38,0));
    transitin: .3s;
}

.shadow-bottom {
    position:fixed;
    bottom:-5px;
    z-index:9;
    width: 100%;
    height: 7vh;
    background:
        linear-gradient(
            to top, 
            rgba(34, 37, 38,1),
            rgba(34, 37, 38,0));

    @media screen and (min-width: 1040px) {
        height: 10vh;
        display:none;
    }

    a {
        position: absolute;
        z-index: 9;
        right: 10px;
        bottom: 10px;
        width: 50px;
        height: 50px;
        background: ${props => props.theme.colors.prime};
        border-top-left-radius: 7px;
        border-bottom-right-radius: 7px;
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;

        &:focus {
            outline: none;
            color: black;
        }
    }
}
h2 {
    color: white;
    padding:15px;
    background: ${props => props.theme.colors.darkgray};
    margin:0;

    @media screen and (min-width: 768px) {
        font-size: 1.5rem;
        letter-spacing: 2px;
        font-weight: 300;
    }
}

.card-info { 
    display:flex;
    width: 100%;
    overflow: scroll;
    scroll-behavior: smooth;
    background: ${props => props.theme.colors.darkgray};
    .card {
        width: 50%;
        max-width: 150px;
        min-width: 150px;
        text-align: center;
        margin: 10px 11px;
        height: 225px;
        position: relative;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        overflow: hidden;
       

        @media screen and (min-width: 768px) {
            width: 30%;
            height:300px;
            max-width:300px;
            min-width:300px;
        }

        @media screen and (min-width: 1024px) {
            height: 310px;
            max-width:310px;
            min-width:310px;
        }


        .rb { 
            color: ${props => props.theme.colors.prime};
            background: black;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 2;
            padding: 5px;
            margin: 0;
            width: 40px;
            border-top-right-radius: 10px;
            display:none;
            font-size: 0.9rem;
            line-height: 1.2;
            letter-spacing:1px;
            font-family: Poppins,sans-serif !important; 
            font-weight: 300;
            height: 27px;

            @media screen and (min-width: 768px) {
                padding-bottom: 10px;
                padding-top: 10px;
                font-size: 1.3rem;
                display: block;
            }
            
        }

        .img-link {
            height:185px;
            position: relative;

            @media screen and (min-width: 768px) {
            overflow: hidden;
            height:300px;
            }
        
            &.overlay {
                position: absolute;
                background: rgba(0,0,0,.0);
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                color: white;
                font-size: 1.5rem;
                letter-spacing: 1px;
                z-index:1;

                &:first-child {
                    
                }

                .r-name {
                    position: absolute;
                    top: 0;
                    color: black;
                    background: ${props => props.theme.colors.prime};
                    width: 100%;
                    margin-top: 0;
                    font-size: .8rem;
                    margin: 0;
                    height: 27px;
                    text-align: center;
                    line-height: 30px;
                    text-align: center;
                    padding-right: 0px;
                    z-index:7;

                    @media screen and (min-width: 768px) {
                        padding-bottom: 10px;
                        padding-top: 10px;
                        font-size: 1.2rem;
                        text-align: right;
                        padding-right: 10px;
                    }
                }

                .r-lunchpris {
                    background: #000;
                    color: #fff;
                    padding: 5px;
                    border-top-right-radius: 15px;
                    border-top-left-radius: 15px;
                    font-size: .6rem;
                    width: 100%;
                    position: absolute;
                    bottom: -1px;
                    margin-bottom: 0;
                    z-index: 7;
                    bottom: 44px;

                    @media screen and (min-width: 768px) {
                    font-size: 1rem;
                    letter-spacing: 2px;
                    bottom: 44px;
                    }

                }
            }
            
            img {
                width:auto;
                min-width: 150px;
                height:100%;

                @media screen and (min-width: 768px) {
                width: 100%;
                height: auto;
                }
            }
            .card-img {
                height: 100%;
                width: 100%;
            }
        }
        .card-info-holder {
            color: white;
            display:flex;
            justify-content: space-between;
            align-items: center;
            background: ${props => props.theme.colors.lightgray};
            align-items: center;
            position: absolute;
            bottom: 0;
            width: 100%;
            z-index: 3;

            @media screen and (min-width: 768px) {
                height: 47px;
            }

            .phone {
                background: transparent;
                border: none;
                width:48px;
                margin-left:0px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: 0;
                position: relative;
                

                a {
                    width: 48px;
                    height: 48px;
                    position: absolute;
                    opacity:0;
                }

                svg {
                    font-size: 1.5rem;
                    color: white;

                    @media screen and (min-width: 768px) {
                    font-size: 2rem;
                    }
                }
            }

            .modal-btn {
                display:flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                color: white;
                border-top-left-radius: 10px;
                justify-content: center;
                padding: 0;
                margin: 0;
                font-size: 1rem;
                padding-right: 10px;
                height: 48px;
                width: 48px;
                transition: .3s;
                background: transparent;
                border: none;
                

                &:hover {
                    cursor: pointer;
                    color: ${props => props.theme.colors.prime};

                }

                &:focus {
                    outline: none;
                }

                @media screen and (min-width: 768px) {
                    font-size: 1.8rem;
                    padding-right: 10px;
                    height: auto;
                    width: auto;
                }

                svg {
                    font-size:1.5rem;
                    width: 100%;
                    height: 100%;
                    padding: 10px;
                }
            }
        }
    }
}

.overlay-full-full {
    background: rgba(0,0,0,.70);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 83;
    left: 0;
    display:none;
}
`
