import styled from "styled-components"

export const NavMobil = styled.div`
  a {
    text-decoration: none;
  }

  ul {
    padding: 0;
    font-family: "Muli Regular", Arial, Helvetica, sans-serif;
    font-size: 1.2rem;
    margin:0;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
    padding-top: 35px;
    
    @media screen and (min-width: 1024px) {
    display: none;
    }

    li {
      display: flex;
      justify-content:center;
      align-items:center;
      transition: opacity 0.5s ease;
      cursor: pointer;
      position:relative;
      width:auto;
      text-align: center;
      margin: 5px;
      background: ${props => props.theme.colors.prime};
      min-width: 45%;
      border-top-left-radius: 7px;
      border-bottom-right-radius: 7px;
      min-height: 48px;

      @media screen and (min-width: 768px) {
        min-width: 20%;
      }
  
      a {
        font-size: .7rem;
        padding:10px;
        color: white;
        letter-spacing: 3.2px;
        text-transform: uppercase;
        color: black;
        transition:.3s;
        background: transparent;
        border: none;
        border-radius: 0px;
        width:100%;
        height: 100%;
      }
    }
  }

  
  .anslut {
    width: 100%;
    background: black;
    border: 1px solid ${props => props.theme.colors.prime};

    a {
      color: white;
    }
  }
`
