import React from 'react';
import { Link } from 'gatsby';

//Styles 
import { NavMobil } from "./MobilMenu.styles"

const Menu = ({ items }) => {

    return(
        <NavMobil id="top-menu">
            <ul>
            <li className="anslut"><Link to={'#newrestaurang'}>Anslut/Uppdatera restaurang</Link></li>
                {items.map(item => ( 
                <>
                    {item.class !== 'lunch' && 
                    <li key={item.id}  className={item.class}>
                        <Link className={item.name} id={item.idmobile} to={item.link} >
                            {item.name}
                        </Link>                        
                    </li>
                    }
                </>
            ))}
            </ul>
            <ul>
            {items.map(item => ( 
                <>
                    {item.class === 'lunch' && 
                    <li key={item.id}  className={item.class}>
                        <Link className={item.name} id={item.idmobile} to={item.link}>
                            {item.name}
                        </Link>                        
                    </li>
                    }
                </>
            ))}
            </ul>
        </NavMobil>
    )
}

export default Menu;
