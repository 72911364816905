import { useStaticQuery, graphql } from 'gatsby';



export const useFrontcardsDataQuery = () => {
    const data = useStaticQuery(graphql`
    query frontcardsQuery {
        markdownRemark(frontmatter: {type: {eq: "frontcards"}}) { 
            frontmatter {
                type
                bildtest {
                    childImageSharp{
                        fluid(maxWidth:1600){
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                frontcards {
                    id 
                    gaclassimg
                    gaclasstel
                    gaclassmodal
                    gaclassmerom
                    name
                    mattyp
                    bild {
                        childImageSharp{
                        fluid(maxWidth:600){
                            ...GatsbyImageSharpFluid
                            }
                        }
                    }
                    link
                    title
                    lunch
                    otidrad1left
                    otidrad1right
                    otidrad2left
                    otidrad2right
                    otidrad3left
                    otidrad3right
                    otidrad4left
                    otidrad4right
                    telefon
                    teltext
                    address
                    excerpt
                    menuid
                    fin
                    buffe
                    published
                        }
                    }
                }
            }
    `)
    return data.markdownRemark.frontmatter
}