import React, { Suspense } from "react"

// Components
import Layout from 'components/Layout'
import SEO from "components/SEO"
import Hero from "components/Hero"
import Header from "components/Header"
import Newcard from "components/Newcards"
import Footer from "components/Footer"
//const Newcard = React.lazy(() => import("components/Newcards"));
//const Footer = React.lazy(() => import("components/Footer"));

const IndexPage = () => {
//const isSSR = typeof window === "undefined"

  
  const schema = {
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "Restauranger Borås",
  "description":"Vi har samlat samtliga restauranger i Borås som du når med ett klick. Det är enkelt att hitta din restaurang i Borås.",
  "url": "https://www.restaurangerboras.se/",
  "logo": "https://www.restaurangerboras.se/favicon-32x32.png?v=e1267cf38663faa84cf3b3fd18dd3050"
  }

return(
  <Layout>
    <SEO title="Restauranger Borås | Samtliga restauranger i Borås" schemaMarkup={schema}/>
    <Header />
    <Hero />
    <Newcard />
    <Footer /> 
      {/* {!isSSR && (
      <Suspense fallback={<div style={{background: "green"}}>Laddas...</div>}>
        <>
          <Newcard />
          <Footer /> 
        </>
      </Suspense>
      )} */}
  </Layout>
)
  
}

export default IndexPage

