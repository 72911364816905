import styled from "styled-components"

export const TelefonFormHolder = styled.div`
background:#222629;
width:100%;
display:flex;
justify-content:center;
align-items:center;
flex-direction:column;
height: 100%;
border-radius: 7px;

p {
    color: white;
    width: 90%;
    margin: 0 auto;
    font-size: .8rem;
}

.mina-uppgifter {
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items:flex-start;
    margin-bottom:70px;
    width: calc(100% - 15px);
    margin: 0 auto;
    padding-top: 0px;
    @media screen and (min-width: 768px) {
        flex-direction:row;
        justify-content:space-around;
    }

    .row-del {
        width:90%;
        margin:0 auto;

        .new {
            margin: 0 auto;
            height:50px; 
        
            input {
                height:40px;
                border:none;
                border-radius:5px;
                width:90%;
                margin-bottom:15px;
                padding-left:15px;
                color:white;
                background: black;

                &:focus {
                    outline:1px solid green;;
                }
            }
            label {
                font-family: 'Nunito', sans-serif;

                span {
                    bottom: 10px;
                }
            }
        }
    }
}

.form-end {
    padding:5px;
    text-align:left;
    display: flex;
    flex-direction: column;
    
    .gdpr {
        width: 90%;
        margin: 5px auto;
        font-family: 'Nunito', sans-serif;
        font-size:.8rem;
        color:white;

        input {
            margin-right:10px;
            height: 20px;
            width: 20px;
        }
        label {
            position: relative;
            top: -5px;
            font-size: .6rem;
        }
    }
    .button {
        background:${props => props.theme.colors.prime};
        color:black;
        transition:.3s;
        padding:7px 15px;
        border: 1px solid ${props => props.theme.colors.prime};
        font-family:'Nunito', sans-serif;
        text-transform:uppercase;
        text-align:center;
        border-radius:50px;
        max-width:300px;
        width:50%;
        margin: 0 auto;

        :hover {
            background:transparent;
            border:1px solid ${props => props.theme.colors.prime};
            color:${props => props.theme.colors.prime};
            cursor:pointer;
        }
    }
}


.mina-uppgifter {
    .row-del {
        .new {
            width: 100%;
            height:50px;
            position: relative;
            text-align: left;
            overflow: hidden;

            @media screen and (min-width: 768px) {
                height: 70px;
            }
            
            input {
                width: calc(100% - 25px);
                height: 100%;
                margin:0;
                font-size:1.2rem;
                padding:0;
                padding-left:25px;

                &::focus {
                    outline: none;
                    .label-kontaktperson {
                        transform:translateY(-150%);
                    }
                }
            }

            label {
                position:absolute;
                bottom: 0px;
                color: white;
                height: 100%;
                width: 100%;
                left: 0%;
                pointer-events: none;
                text-align: left;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0px;
                    transform:translateX(-101%);
                    bottom: 0px;
                    height: 100%;
                    width: 100%;
                    border-bottom: 1px solid ${props => props.theme.colors.prime};
                    transition: all .3s ease;
                }

                .content-input {
                    position: absolute;
                    bottom: 10px;
                    left: 25px;
                    font-size: 1.3rem;
                    color: gray;
                    transition: all .3s ease;

                    @media screen and (min-width: 768px) {
                        bottom: 15px;
                    }
                }
            }

        }
    }
}

.new input:focus + label .content-input,
.new input:valid + label .content-input
{
    transform: translateY(-150%);
    color: ${props => props.theme.colors.prime} !important;
    font-size: .85rem !important;
}


.new input:focus + label::after
{
    transform: translateX(0%) !important;
    
}

`