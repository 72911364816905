import React, { Suspense } from "react"
import CardU100 from 'components/FrontCards/CardU100'
import CardU90 from 'components/FrontCards/CardU90'
import NewRestaurang from 'components/FrontCards/NewRestaurang'
import { Link } from 'gatsby'
import Card from 'components/FrontCards/Card'
import { gsap } from "gsap";

// Hoks
import { useFrontcardsDataQuery } from 'Hooks/useFrontcardsDataQuery'

// Styles
import { CardHolder } from "./Newcards.styles"

let tl = gsap.timeline();

class Newcard extends React.Component {
    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
    }

    closeModal(){
        tl.to('.overlay-full-full', 0, {
            display: "none",
        })
       tl.to('.modal', .3, {
            ease: "Power4.easeOut",
            bottom: "-120%",
        })
    }

    render(){
        const frontcardQuery = useFrontcardsDataQuery();
        const isSSR = typeof window === "undefined"

        return(
            <CardHolder>
                <>
                    <div className="overlay-full-full" onClick={this.closeModal}></div>
                     
                    <NewRestaurang />
                   

                        <>   
                            <h2 id="sushi">Sushi</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.mattyp === 'sushi' && item.published === true &&
                                            <Card 
                                            id={item.id}
                                            gaclassimg={item.gaclassimg}
                                            link={item.link}
                                            title={item.title}
                                            lunch={item.lunch}
                                            bild={item.bild}
                                            telefon={item.telefon}
                                            gaclasstel={item.gaclasstel}
                                            gaclassmodal={item.gaclassmodal}
                                            teltext={item.teltext}
                                            address={item.address}
                                            otidrad1left={item.otidrad1left}
                                            otidrad1right={item.otidrad1right}
                                            otidrad2left={item.otidrad2left}
                                            otidrad2right={item.otidrad2right}
                                            otidrad3left={item.otidrad3left}
                                            otidrad3right={item.otidrad3right}
                        
                                            />  
                                        }
                                    </>
                                ))} 
                            </div>
                        </>
                        <>   
                            <h2 id="indisk">Indisk</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.mattyp === 'indisk' && 
                                        <Card 
                                        id={item.id}
                                        gaclassimg={item.gaclassimg}
                                        link={item.link}
                                        title={item.title}
                                        lunch={item.lunch}
                                        bild={item.bild}
                                        telefon={item.telefon}
                                        gaclasstel={item.gaclasstel}
                                        gaclassmodal={item.gaclassmodal}
                                        teltext={item.teltext}
                                        address={item.address}
                                        otidrad1left={item.otidrad1left}
                                        otidrad1right={item.otidrad1right}
                                        otidrad2left={item.otidrad2left}
                                        otidrad2right={item.otidrad2right}
                                        otidrad3left={item.otidrad3left}
                                        otidrad3right={item.otidrad3right}
                    
                                        />   
                                        }
                                    </>
                                ))} 
                            </div>
                        </>
                        <>   
                            <h2 id="husmanskost">Husmanskost</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.mattyp === 'husmanskost' && 
                                        <Card 
                                        id={item.id}
                                        gaclassimg={item.gaclassimg}
                                        link={item.link}
                                        title={item.title}
                                        lunch={item.lunch}
                                        bild={item.bild}
                                        telefon={item.telefon}
                                        gaclasstel={item.gaclasstel}
                                        gaclassmodal={item.gaclassmodal}
                                        teltext={item.teltext}
                                        address={item.address}
                                        otidrad1left={item.otidrad1left}
                                        otidrad1right={item.otidrad1right}
                                        otidrad2left={item.otidrad2left}
                                        otidrad2right={item.otidrad2right}
                                        otidrad3left={item.otidrad3left}
                                        otidrad3right={item.otidrad3right}
                    
                                        />  
                                        }
                                    </>
                                ))}
                            </div>
                        </> 
                        <>   
                            <h2 id="buffe" >Buffe</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.buffe === 'ja' && item.title !== 'East' &&
                                        <Card 
                                        id={item.id}
                                        gaclassimg={item.gaclassimg}
                                        link={item.link}
                                        title={item.title}
                                        lunch={item.lunch}
                                        bild={item.bild}
                                        telefon={item.telefon}
                                        gaclasstel={item.gaclasstel}
                                        gaclassmodal={item.gaclassmodal}
                                        teltext={item.teltext}
                                        address={item.address}
                                        otidrad1left={item.otidrad1left}
                                        otidrad1right={item.otidrad1right}
                                        otidrad2left={item.otidrad2left}
                                        otidrad2right={item.otidrad2right}
                                        otidrad3left={item.otidrad3left}
                                        otidrad3right={item.otidrad3right}
        
                                        />                 
                                        }
                                    </>
                                ))} 
                            </div>
                        </>
                        <>   
                            <h2 id="thai">Asiatisk</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.mattyp === 'asiatisk' && item.published  && 
                                        <Card 
                                        id={item.id}
                                        gaclassimg={item.gaclassimg}
                                        link={item.link}
                                        title={item.title}
                                        lunch={item.lunch}
                                        bild={item.bild}
                                        telefon={item.telefon}
                                        gaclasstel={item.gaclasstel}
                                        gaclassmodal={item.gaclassmodal}
                                        teltext={item.teltext}
                                        address={item.address}
                                        otidrad1left={item.otidrad1left}
                                        otidrad1right={item.otidrad1right}
                                        otidrad2left={item.otidrad2left}
                                        otidrad2right={item.otidrad2right}
                                        otidrad3left={item.otidrad3left}
                                        otidrad3right={item.otidrad3right}
                    
                                        />  
                                        }
                                    </>
                                ))}
                            </div>
                        </> 
                        <>   
                            <h2 id="mellanost">Mellanöst</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.mattyp === "Mellanöst" && 
                                            <Card 
                                            id={item.id}
                                            gaclassimg={item.gaclassimg}
                                            link={item.link}
                                            title={item.title}
                                            lunch={item.lunch}
                                            bild={item.bild}
                                            telefon={item.telefon}
                                            gaclasstel={item.gaclasstel}
                                            gaclassmodal={item.gaclassmodal}
                                            teltext={item.teltext}
                                            address={item.address}
                                            otidrad1left={item.otidrad1left}
                                            otidrad1right={item.otidrad1right}
                                            otidrad2left={item.otidrad2left}
                                            otidrad2right={item.otidrad2right}
                                            otidrad3left={item.otidrad3left}
                                            otidrad3right={item.otidrad3right}
                        
                                            />          
                                        }
                                    </>
                                ))}
                            </div>
                        </> 
                        <>   
                            <h2 id="finrestaurang">Finrestaurang</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.fin === 'ja' && 
                                            <Card 
                                            id={item.id}
                                            gaclassimg={item.gaclassimg}
                                            link={item.link}
                                            title={item.title}
                                            lunch={item.lunch}
                                            bild={item.bild}
                                            telefon={item.telefon}
                                            gaclasstel={item.gaclasstel}
                                            gaclassmodal={item.gaclassmodal}
                                            teltext={item.teltext}
                                            address={item.address}
                                            otidrad1left={item.otidrad1left}
                                            otidrad1right={item.otidrad1right}
                                            otidrad2left={item.otidrad2left}
                                            otidrad2right={item.otidrad2right}
                                            otidrad3left={item.otidrad3left}
                                            otidrad3right={item.otidrad3right}
                        
                                            />      
                                        }
                                    </>
                                ))} 
                            </div>
                        </> 
                        <>   
                            <h2 id="texmex">Texmex</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.mattyp === 'texmex' && 
                                        <Card 
                                        id={item.id}
                                        gaclassimg={item.gaclassimg}
                                        link={item.link}
                                        title={item.title}
                                        lunch={item.lunch}
                                        bild={item.bild}
                                        telefon={item.telefon}
                                        gaclasstel={item.gaclasstel}
                                        gaclassmodal={item.gaclassmodal}
                                        teltext={item.teltext}
                                        address={item.address}
                                        otidrad1left={item.otidrad1left}
                                        otidrad1right={item.otidrad1right}
                                        otidrad2left={item.otidrad2left}
                                        otidrad2right={item.otidrad2right}
                                        otidrad3left={item.otidrad3left}
                                        otidrad3right={item.otidrad3right}
            
                                        />
                                        }
                                    </>
                                ))}
                            </div>
                        </>
                        <>   
                            <h2 id="pasta">Pasta</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.mattyp === 'pasta' && 
                                        <Card 
                                        id={item.id}
                                        gaclassimg={item.gaclassimg}
                                        link={item.link}
                                        title={item.title}
                                        lunch={item.lunch}
                                        bild={item.bild}
                                        telefon={item.telefon}
                                        gaclasstel={item.gaclasstel}
                                        gaclassmodal={item.gaclassmodal}
                                        teltext={item.teltext}
                                        address={item.address}
                                        otidrad1left={item.otidrad1left}
                                        otidrad1right={item.otidrad1right}
                                        otidrad2left={item.otidrad2left}
                                        otidrad2right={item.otidrad2right}
                                        otidrad3left={item.otidrad3left}
                                        otidrad3right={item.otidrad3right}
            
                                        />
                                        }
                                    </>
                                ))}
                            </div>
                        </>
                        <>   
                            <h2 id="hamburgare">Hamburgare</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.mattyp === 'hamburgare' && 
                                            <Card 
                                            id={item.id}
                                            gaclassimg={item.gaclassimg}
                                            link={item.link}
                                            title={item.title}
                                            lunch={item.lunch}
                                            bild={item.bild}
                                            telefon={item.telefon}
                                            gaclasstel={item.gaclasstel}
                                            gaclassmodal={item.gaclassmodal}
                                            teltext={item.teltext}
                                            address={item.address}
                                            otidrad1left={item.otidrad1left}
                                            otidrad1right={item.otidrad1right}
                                            otidrad2left={item.otidrad2left}
                                            otidrad2right={item.otidrad2right}
                                            otidrad3left={item.otidrad3left}
                                            otidrad3right={item.otidrad3right}
                        
                                            />                 
                                        }
                                    </>
                                ))} 
                            </div>
                        </>
                        <>    
                            <h2 id="pizza">Pizza</h2>
                            <div className="card-info">  
                                {frontcardQuery.frontcards.map(item => ( 
                                    <>
                                        {item.mattyp === 'pizza' && 
                                            <Card 
                                            id={item.id}
                                            gaclassimg={item.gaclassimg}
                                            link={item.link}
                                            title={item.title}
                                            lunch={item.lunch}
                                            bild={item.bild}
                                            telefon={item.telefon}
                                            gaclasstel={item.gaclasstel}
                                            gaclassmodal={item.gaclassmodal}
                                            teltext={item.teltext}
                                            address={item.address}
                                            otidrad1left={item.otidrad1left}
                                            otidrad1right={item.otidrad1right}
                                            otidrad2left={item.otidrad2left}
                                            otidrad2right={item.otidrad2right}
                                            otidrad3left={item.otidrad3left}
                                            otidrad3right={item.otidrad3right}
                        
                                            />                 
                                        }
                                    </>
                                ))} 
                            </div>
                        </>
                    
                    
                    <div className="shadow-bottom">
                        <Link to={'#top-menu'}>Menu</Link>
                    </div>
    
                </>
            </CardHolder>
        )
    }

    
}

export default Newcard;
