import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';

//Styles 
import { Nav } from "./Menu.styles"

const Menu = ({ items }) => {
    return(
        <Nav>
            {items.map(item => ( 
                <li key={item.id}  className={item.class}>
                    <button className={item.name} id={item.id} onClick={() => {scrollTo(item.link)}}>
                        {item.name}
                    </button>                        
                </li>
            ))}
        </Nav>
    )
}

export default Menu;