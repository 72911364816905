import React from 'react'
import scrollTo from 'gatsby-plugin-smoothscroll';

// Styles
import { HeroHolder } from "./Hero.styles"

const Hero = () => {
    return (
    <HeroHolder>   
        <div className="hero-content">
            <h1 id="herorubrik">Dina restauranger i Borås</h1>
            <p>Underlättar restaurangkulturen i Borås!</p>
            <button className="hero-btn" onClick={() => {scrollTo('#newrestaurang')}}>
                Lägg till/uppdatera restaurang
            </button> 
        </div>
    </HeroHolder>
   )
}

export default Hero 
