import React, { Suspense } from "react"
//import Modal from '../Modal'
import { Link } from 'gatsby'
import { FaPhoneSquareAlt } from 'react-icons/fa';
import gsap from "gsap";
import Img from "gatsby-image"
import {PassiveListener} from 'react-event-injector';


const Modal = React.lazy(() => import("../Modal"));

let tl = gsap.timeline();

class Card extends React.Component{

    constructor(props) {
        super(props);
        this.openModal  = this.openModal.bind(this);
        this.onHoverInfo  = this.onHoverInfo.bind(this);

        this.state = {
            modalBtnClicked: false
        }
    }

    onHoverInfo(){
        this.setState({
            modalBtnClicked: true
        })
    }

    openModal(event){
        
        let clickParent = event.target.parentNode;
        let mainParent = clickParent.parentNode;
        let childModal = mainParent.childNodes;
        let thisModal = null;
        for(const loop of childModal ) {
            if(loop.classList.contains('modal')) {
                thisModal = loop;
                if(thisModal){
                    tl.to(thisModal, .3, {
                        bottom: '0px',
                    })
                    .to('.overlay-full-full', .31, {
                        display: "block",
                    })
                }
            } 
            
        }     
    }

    render(){
    const isSSR = typeof window === "undefined"

    return (
        <div key={this.props.id} className="card">
        <div className="rb">RB</div>

        <Link className={this.props.gaclassimg + ` overlay img-link` }  to={this.props.link}>
                <p className="r-name">{this.props.title}</p>
{/* <p className="r-lunchpris">Lunchpris {this.props.lunch}:-</p> */}
                <Img width="300" height="350" className="card-img ga-bild" fluid={this.props.bild.childImageSharp.fluid} alt={this.props.title} />
            </Link>
        <div className="card-info-holder">
            <p  className="phone">
                <FaPhoneSquareAlt className="tel-svg"/>
                <a href={this.props.telefon} className={this.props.gaclasstel } title="telefon">telefon</a>
            </p>
            <PassiveListener 
                    ontouchstart={this.onHoverInfo} 
                    onTouchEnd={this.openModal}>
                <button className={this.props.gaclassmodal + ` modal-btn`} 
                        onClick={this.openModal} 
                        onMouseOver={this.onHoverInfo} 
                        >info
                </button>
            </PassiveListener>
            
        </div>
        {!isSSR && (
            <Suspense fallback={<div style={{background: "green"}}>Laddas...</div>}>
                <>
                {
                    this.state.modalBtnClicked?
                    <Modal
                    title={this.props.title}
                    teltext={this.props.teltext}
                    address={this.props.address}
                    otidrad1left={this.props.otidrad1left}
                    otidrad1right={this.props.otidrad1right}
                    otidrad2left={this.props.otidrad2left}
                    otidrad2right={this.props.otidrad2right}
                    otidrad3left={this.props.otidrad3left}
                    otidrad3right={this.props.otidrad3right}
                    link={this.props.link}
                    lunch={this.props.lunch}
                    /> 
                    : null
                }
                </>
            </Suspense>
        )}                            
    </div>
   )
}}

export default Card 
