import styled from "styled-components"

export const Nav = styled.ul`
  padding: 0;
  font-family: "Muli Regular", Arial, Helvetica, sans-serif;
  font-size: 1.2rem;
  margin:0;
  display: none;
  justify-content: center;
  align-items: center;
  overflow: scroll;
  position: relative;
  height: 50px;
  width: 100%;


  @media screen and (min-width: 768px) {
    height: 100px;
  }

  @media screen and (min-width: 1024px) {
    height: 30px;
    margin-top: 5px;
    padding-bottom: 5px;
    display: flex;

  }
  
  a {
    text-decoration: none;
  }

  li {
    display: flex;
    justify-content:center;
    align-items:center;
    height: 50px;
    transition: opacity 0.5s ease;
    cursor: pointer;
    position:relative;
    text-align: center;

    @media screen and (min-width: 768px) {
      height: 100px;
    }

    @media screen and (min-width: 768px) {
      height: 30px;
    }

    &:after {
      content: "";
      height: 20px;
      width: 1px;
      background: white;
      display: block;
      position: absolute;
      right: 0px;

      @media screen and (min-width: 768px) {
        height: 50px;
      }

      @media screen and (min-width: 768px) {
        height: 15px;
        transform: rotate(15deg);
      }
    }

    button {
      font-size: .7rem;
      padding: 0;
      color: white;
      letter-spacing: 3.2px;
      text-transform: uppercase;
      color: white;
      transition:.3s;
      background: transparent;
      border: none;
      border-radius: 0px;
      height: 100%;

      @media screen and (min-width: 768px) {
        font-size: 1rem;
      }

      @media screen and (min-width: 1024px) {
        font-size: 10px;
        font-weight: 200;
        margin-right: 5px;
        margin-left:5px;
      }

      :focus {
        outline:none;
        color: ${props => props.theme.colors.prime};
      }

      :hover {
        color: ${props => props.theme.colors.prime};
        cursor: pointer;
      }
    }
  }
`
