import React from 'react'

// Styles
import { NewrestaurangFormHolder } from "./KontaktForm.styles"

const Newrestaurang = () => (
    <NewrestaurangFormHolder id="newrestaurang">
        <h3 >Lägg till din restaurang eller uppdatera befintlig information</h3>
        <form 

            name="Ny Restaurang Form"
            method="POST"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"        
        >
         <input type="hidden" name="form-name" value="Ny Restaurang Form" />

          <div className="mina-uppgifter">
            <div className="row-del">
                <div className="new">
                    <input type="text" name="Kontaktperson" id="Kontaktperson" required/>
                    <label for="Kontaktperson" className="label-kontaktperson">
                        <span className="content-input">Kontaktperson</span>
                    </label>
                </div>

                <div className="new">
                    <input type="text" name="Restaurang" id="Restaurang" required/>
                    <label for="Restaurang" className="label-kontaktperson">
                        <span className="content-input">Restaurangens namn</span>
                    </label>
                </div>

            </div>
            
            <div className="row-del">

                <div className="new">
                    <input type="number" name="Telefon" id="Telefon" required/>
                    <label for="Telefon" className="label-kontaktperson">
                        <span className="content-input">Telefon</span>
                    </label>
                </div>

                <div className="new">
                    <input type="text" name="Epost" id="Epost" required/>
                    <label for="Epost" className="label-kontaktperson">
                        <span className="content-input">Epost</span>
                    </label>
                </div>
                      
            </div>
            </div> {/* end mina-uppgifter */}

            <div className="form-end">                
                <div className="gdpr" id="sushi3">
                <label for="gdp">
                <input type="checkbox" name="checkbox_kon" id="gdp" required 
                value="
                Genom att jag har skickat detta meddelande samtycker jag till att 
                ni tar del av de personuppgifter jag har valt att delge.
                "/>
                
                    restaurangerboras.se lagrar bokningshistorik och personuppgifter som 
                    kunden lämnar och behandlar dessa uppgifter i enlighet med gällande 
                    GDPR. Genom att skicka offertförfågan 
                    godkänner du användaravtalet.
                </label>
                </div>
                <input className="button " type="submit" value="Skicka" />
            </div>
      </form>
    </NewrestaurangFormHolder>

)

export default Newrestaurang
