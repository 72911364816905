import React from 'react'

// Styles
import { TelefonFormHolder } from "./TelefonForm.styles"

const Telefonform = (props) => (
    <TelefonFormHolder>
        <p className="telefon-text">{props.telefontext}
            
        </p>
        <form 
            name="Ny Telefon Form"
            method="POST"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"        
        >
        <input type="hidden" name="form-name" value="Ny Telefon Form" />

            <div className="mina-uppgifter">
                <div className="row-del">     
                    <div className="new">
                        <input type="number" name="Telefon" id="Telefon-anons" />
                        <label for="Telefon-anons" className="label-kontaktperson">
                            <span className="content-input">Telefon</span>
                        </label>
                    </div>      
                </div>
            </div> {/* end mina-uppgifter */}

            <div className="form-end"> 
                <div className="gdpr">
                <label for="sponsor-gdpr">
                    <input id="sponsor-gdpr" type="checkbox" name="checkbox_kon" required 
                    value="
                    Genom att jag har skickat detta meddelande samtycker jag till att 
                    ni tar del av de personuppgifter jag har valt att delge.
                    "/>
                    
                        Jag acceperar att restaurangerboras.se lagrar min telefonnummer.
                    </label>
                </div>
                <input className="button " type="submit" value="Skicka" />
            </div>
      </form>
    </TelefonFormHolder>

)

export default Telefonform
