import React from 'react'
import { Link } from 'gatsby'

import TelefonForm from 'components/TelefonForm'

// Styles
import { AddCard } from "./AddRestaurang.styles"


class AddRestaurang extends React.Component{
    
    render(){ 

        return(
        <AddCard>   
            <TelefonForm 
            telefontext="Om du vill att din restaurang ska synas på 
            denna raden fyll i din telefonnummer så fixar vi det."
            />
        </AddCard>
        )
    }
}
export default AddRestaurang
