import React from 'react'
import AddRestaurang from '../AddRestaurang'
import Card from '../Card'


// Hooks
import { useFrontcardsDataQuery } from 'Hooks/useFrontcardsDataQuery'

class CardU100 extends React.Component{
   

    render(){ 
        const frontcardQuery = useFrontcardsDataQuery()

        return(
        <>   
        <h2 id="u100">Lunch under 100kr</h2>
        <div className="card-info">  
            {frontcardQuery.frontcards.map(item => ( 
                <>
                    {item.lunch <= 100 && item.lunch > 90 &&
                    <Card 
                    id={item.id}
                    gaclassimg={item.gaclassimg}
                    link={item.link}
                    title={item.title}
                    lunch={item.lunch}
                    bild={item.bild}
                    telefon={item.telefon}
                    gaclasstel={item.gaclasstel}
                    gaclassmodal={item.gaclassmodal}
                    teltext={item.teltext}
                    address={item.address}
                    otidrad1left={item.otidrad1left}
                    otidrad1right={item.otidrad1right}
                    otidrad2left={item.otidrad2left}
                    otidrad2right={item.otidrad2right}
                    otidrad3left={item.otidrad3left}
                    otidrad3right={item.otidrad3right}

                    />    
                    }
                </>
            ))} 
        </div>
        </>
        )
    }
}
export default CardU100
