import styled from "styled-components"

export const AddCard = styled.div`
        width: 50%;
        max-width: 350px;
        min-width: 350px;
        text-align: center;
        margin: 10px 11px;
        height: 223px;
        position: relative;
        display: flex;
        flex-direction: column;
        border: 1px solid ${props => props.theme.colors.prime};
        display:flex;
        justify-content:center;
        align-items:center;
        border-radius: 10px;
        overflow:hidden;

        @media screen and (min-width: 768px) {
            width: 30%;
            height:300px;
            max-width:300px;
            min-width:300px;
        }

        @media screen and (min-width: 1024px) {
            height: 300px;
            max-width:310px;
            min-width:310px;
        }

        .add-card {
            border:1px solid ${props => props.theme.colors.prime};
            width:75%;
            height:75%;
            display:flex;
            justify-content:center;
            align-items:center;
            transition:.3s;
            border-radius: 10px;

            &:hover {
                background: ${props => props.theme.colors.prime};
                cursor: pointer;
            }

            a {
                color: white;
                text-transform:uppercase;
            }
        }
`