import styled from "styled-components"
import heromobile from "images/hero-bg-mobile.jpg"
import herodesk from "images/hero.jpg"


export const HeroHolder = styled.div`
//background:linear-gradient(to bottom, rgba(39, 45, 45,1),rgba(39, 45, 45,.65),rgba(39, 45, 45,.33),rgba(39, 45, 45,1)),url(${heromobile});
background: black;
background-position: center;
background-size: cover;
width:100%;
height:auto;
position:relative;
display:none;
justify-content:center;
align-items:center;
flex-direction:column; 
height:30vh;
max-height: 900px;

@media screen and (min-width: 768px) {
    background:linear-gradient(to bottom, rgba(39, 45, 45,1),rgba(39, 45, 45,.65),rgba(39, 45, 45,.33),rgba(39, 45, 45,1)),url(${herodesk});
    height: 50vh;
    display:flex;
}


@media screen and (min-width: 1024px) {
    background:linear-gradient(to bottom, rgba(39, 45, 45,1),rgba(39, 45, 45,.65),rgba(39, 45, 45,.33),rgba(39, 45, 45,1)),url(${herodesk});
    background-size: cover;
    height:85vh;
    max-height: 1000px;

  }


.hero-content {
    width: 100%;
    background: transparent;
    flex-flow: row wrap;
    flex-direction:column;
    display: flex;

        h1 {
            margin-bottom: 5px;
            font-size: 1.5rem;
            color: white;
            text-align: center;
            letter-spacing: 5px;
            line-height: 32px;
            margin-top: 0;

            @media screen and (min-width: 768px) {
                font-size: 2.7rem;
              }

              @media screen and (min-width: 1040px) {
                font-size: 4rem;
                font-weight: 400;
              }
        } 
        p {
            width: 100%;
            text-align: center;
            letter-spacing: 2px;
            font-size: .7rem;
            color: white;
            text-transform: uppercase;

            @media screen and (min-width: 768px) {
                font-size: 1.2rem;
            }

            @media screen and (min-width: 1040px) {
                font-size: 1.5rem;
                font-weight: 100;
                letter-spacing: 2px;
            }

              
        }

        .hero-btn {
            background: ${props => props.theme.colors.prime};
            color: black;
            padding:10px 35px;
            border-radius:50px;
            width: 200px;
            border: none;
            margin: 0 auto;
            transition: .3s;
            display: none;

            &:focus {
                outline: none;
            }

            &:hover {
                background: white;
                cursor:pointer;
                color: black;
            }

            @media screen and (min-width: 768px) {
                width: auto;
                height: 50px;
                font-size: 1.2rem;
                letter-spacing: 1px;
                display: block;
            }
        }
       
    }
`